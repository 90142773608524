import { AppRoute } from '@/router/routes';
import { Bot } from 'ignite360-core';
import { Component, Vue } from 'vue-property-decorator';

@Component
export class ProjectViewMixin extends Vue {
  get bot(): Bot | undefined {
    return this.$store.state.bots.bots.find((bot: Bot) => bot.id === this.$route.params.id);
  }

  mounted() {
    if (!this.bot) {
      // TODO test in production with more delay
      return this.$router.replace({ name: AppRoute.Projects });
    }
  }
}
