
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { BotDomain, DeepPartial, DomainData, RestaurantDomainDataContent } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type BookingSettings = Pick<RestaurantDomainDataContent, 'bookingUrl'>;

@Component({
  name: 'restaurant-booking-settings-card',
})
export default class RestaurantBookingSettingsCard extends mixins(BotMixin) {
  bookingSettings: BookingSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.bookingSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBot({
        id: this.bot.id,
        data: {
          domainData: {
            [BotDomain.Restaurant]: this.bookingSettings,
          } as DeepPartial<DomainData>,
        },
      });
      this.$notify.success('Successfully updated booking-settings')
    } catch (e) {
      this.$notify.error({
        title: 'Updating booking-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.bookingSettings = this.resetValue();
  }

  private resetValue(): BookingSettings {
    return {
      bookingUrl: this.bot.domainData[BotDomain.Restaurant]?.bookingUrl || '',
    };
  }
}
